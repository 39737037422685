import { javascript } from '@api/index'
import {
  StoreMode,
  useDefaultDocxSettings,
  useDocxSettings,
  useHydration
} from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useOrganisationStore } from './organisation.store'
import { uniqueArray } from '@avvoka/shared'

export type TemplateVersionStoreType = ReturnType<
  typeof useTemplateVersionStore
>
export const allTemplateVersionStores = ref<Array<TemplateVersionStoreType>>([])

export type TemplateVersionDataType = Backend.Models.TemplateVersion & {
  avv_roles: Array<Backend.Questionnaire.ParticipantRole>
  cross_reference_indices: Record<string, {
    data_numbered_id?: string
    uuid: string
    number: string
    fullnumber: string
    text: string
    context_start: number
    context: string[]
  }>
  settings_for_frontend: {
    validation_controllers: string[]
  }
}

export const createTemplateVersionStore = (uniqueId: string | number) => {
  const templateVersionStore = defineStore(
    'template-version-store-' + String(uniqueId),
    () => {
      const hydration = useHydration<TemplateVersionDataType>(
        javascript.templateVersion
      )
      const hydrateById = (
        id: number,
        fields: Array<keyof TemplateVersionDataType> = [],
        force = false
      ) => {
        return hydration.hydrate({ id }, fields, force)
      }
      allTemplateVersionStores.value.push(
        templateVersionStore as unknown as TemplateVersionStoreType
      )

      const defaultParticipantRights = computed(
        () =>
          ({
            approve: 'All',
            edit: 'All',
            invite: 'All',
            sign: 'Yes',
            default_email: '',
            default_name: '',
            allowed_email_domains: '',
            manage_comments: true,
            ...useOrganisationStore().defaultParticipantRights,
            follow_user_profiles_rights: false,
            default_role: true,
            lock_email: false
          }) as Backend.Questionnaire.ParticipantRights
      )

      const $default = () => {
        const defaultPartyName = localizeText('general.party') + ' A'
        const defaultRoleName = localizeText('general.role')
        hydration.hydratedData.value = {
          id: -1,
          name: '',
          avv_roles: [
            {
              name: defaultRoleName,
              party: defaultPartyName,
              rights: defaultParticipantRights.value
            }
          ],
          docx_settings: useDefaultDocxSettings(),
          settings_for_frontend: {
            validation_controllers: []
          }
        } as unknown as TemplateVersionDataType
        hydration.hydrated.value = true
        hydration.storeMode.value = StoreMode.NewData
      }

      const roles = hydration.hydratedComputed('avv_roles', (_roles) => {
        // old error caused duplication, can be removed in future when the data is fixed
        return _roles.filter((role, index, self) => self.findIndex(el => el.party == role.party && el.name == role.name) == index)
      })

      return {
        ...hydration,
        hydrateById,
        id: hydration.hydratedComputed('id'),
        defaultParticipantRights,
        roles,
        parties: computed(() => uniqueArray(roles.value.map((p) => p.party))),
        isAgreementOnly: computed(() => {
          return roles.value.every(
            (role) =>
              role.rights.edit !== 'All' && role.rights.edit !== 'Comments'
          )
        }),
        crossReferenceIndices: hydration.hydratedComputed('cross_reference_indices'),
        settings: hydration.hydratedComputed('settings_for_frontend'),
        $default,
        ...useDocxSettings(hydration)
      }
    }
  )
  return templateVersionStore
}

export const useTemplateVersionStore = createTemplateVersionStore('current')
